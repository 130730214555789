.passport-authorize {
    .container {
        margin-top: 30px;
    }
    .scopes {
        margin-top: 20px;
    }
    .buttons {
        margin-top: 25px;
        text-align: center;
    }
    .btn {
        width: 125px;
    }
    .btn-approve {
        margin-right: 15px;
    }
    form {
        display: inline;
    }
}
