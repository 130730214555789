.navbar.navbar-inverse{
	background-color: #111; 
	border-color: #000;
	border-width: 2px;
}
.lb_box-shadow{
	box-shadow:  0 0 20px 0 rgba(0,0,0,0.5);
}

td{
	vertical-align: middle!important;
}

td.lb_code{
	width: 15%;word-break: break-all
}
.dropdown-toggle .caret{
	display: none!important;
}

.datepicker-panel {
    background-color: #fff ;
    color: #333 ;
}
