@import url(https://fonts.googleapis.com/css?family=Raleway:300,400,600);

/*// Variables. Without this import, even if the file is empty, the load of boostrap.scss will fail*/
@import "variables";

/*// Bootstrap*/
/*@import '~bootstrap/dist/css/bootstrap.css';*/
@import '~bootstrap/scss/bootstrap.scss';
@import 'bootstrap';
/*@import '~font-awesome-4.7.0/scss/font-awesome.scss';*/

@import 'learnbrite';
@import "./passport.scss";
